import React from "react";

export default class PopularDomainTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            data: {}
        }
    }

    componentDidMount() {
        fetch("https://cloudflare-assignment.shishir-iyer62.workers.dev/popular-domains").then(res => res.json()).then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    data: result
                });
            },

            (error) => {
                // console.log(error);
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        );
    }

    render() {
        if(this.state.isLoaded && !this.state.error) {
            let table = <tr><th>Rank</th><th>Domain</th><th>Category</th></tr>;

            let rankingEntries = this.state.data.rankingEntries;
            for(let i = 0; i < rankingEntries.length && rankingEntries[i].rank != ""; i++) {
                let re = rankingEntries[i];
                table += <tr><td>{re.rank}</td><td>{re.domain}</td><td>{re.Category}</td></tr>;
            }

            return (
                <div>
                    <h2>Popular Domains</h2>
                    <table>
                        <tr><th>Rank</th><th>Domain</th><th>Category</th></tr>
                        {this.state.data.rankingEntries.map((val, key) => {
                            return (
                                <tr>
                                    <td>{val.rank}</td>
                                    <td>{val.domain}</td>
                                    <td>{val.Category}</td>
                                </tr>
                            );
                        })}
                    </table>
                </div>
            )
        } else return <p>Loading</p>
    }
}