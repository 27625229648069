import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

export default class AttackLayerChart extends React.Component {
    options = {
        plugins: {
            legend: {
                position: 'top',
            },
    
            title: {
                display: true,
                text: "Attack Layer 3",
            },
        },
    };

    constructor(props) {
        super(props);

        ChartJS.register(
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend
        );

        this.state = {
            error: null,
            isLoaded: false,
            data: {}
        }
    }

    componentDidMount() {
        fetch("https://cloudflare-assignment.shishir-iyer62.workers.dev/attack-layer3").then(res => res.json()).then(
            (result) => {
                const data = {
                    labels: [],
                    datasets: [
                        {
                            label: 'Total',
                            data: [],
                            borderColor: 'rgb(255, 99, 132)',
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                    ]
                }

                let totalData = result.data;
                for(const date in totalData) {
                    data.labels.push(date);
                    data.datasets[0].data.push(parseFloat(totalData[date]));
                }

                // console.log(data);

                this.setState({
                    isLoaded: true,
                    data: data
                });
            },

            (error) => {
                // console.log(error);
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        );
    }

    render() {
        // console.log(this.state.isLoaded + " " + JSON.stringify(this.state.data));
        return (this.state.isLoaded) ? <Line options={this.options} data={this.state.data}/> : <p>Loading</p>;
    }
}