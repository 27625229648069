import './App.css';
import TrafficChangeChart from "./TrafficChange.js";
import PopularDomainTable from './PopularDomains.js';
import AttackLayerChart from './AttackLayer.js';

function App() {
    return (
        <div>
            <TrafficChangeChart/>
            <PopularDomainTable/>
            <AttackLayerChart/>
        </div>
    );
}

export default App;
